import { Box, Column, Icon, Row, Typography } from "@doare/ui-components";
import Image from "next/image";
import { Fragment, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Carousel } from "../carousel/carousel.component";
import {
  ContainerBreed,
  ContainerBreeds,
  FieldSet,
  Input,
  Label,
  ToggleGroup,
  ToggleGroupCarousel,
  ToggleGroupItem,
  ToggleGroupItemCarousel,
} from "./form.styles";
import { FemaleIcon, MaleIcon } from "../icons/icons.component";
import { data } from "../../data";
import { useAuth } from "../../hooks/auth";

export const BreedForm = () => {
  const { control } = useFormContext();
  const { gender } = useWatch({
    control,
  });

  const breeds = useMemo(() => {
    return data[gender as "male" | "female"].breeds;
  }, [gender]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Raça</Typography>
      </Box>

      <ContainerBreeds>
        
          <Controller
            name="breed"
            control={control}
            render={({ field: { onChange, value: _value } }) => (
            <ToggleGroupCarousel
              value={_value}
              type="single"
              defaultValue="pug"
              aria-label="Breed"
              onValueChange={(value) => {
                onChange(value ? (value as any) : _value)
                }
              }
            >
                <Carousel>
                  {breeds.map((breed) => (
                      <ContainerBreed>
                        <ToggleGroupItemCarousel
                          key={breed.id}
                          value={breed.name}
                          aria-label={breed.name}
                        >
                          <Image
                            height={80}
                            width={80}
                            src={breed.file || ''}
                            style={{ borderRadius: "16px" }}
                            alt="Character"
                          />
                        </ToggleGroupItemCarousel>
                        <Typography style={{ textAlign: 'center' }}>{breed.name}</Typography>
                      </ContainerBreed>
                  ))}
                </Carousel>
              </ToggleGroupCarousel>
            )}
          />
      </ContainerBreeds>
    </Fragment>
  );
};

export const ViewForm = () => {
  const { control } = useFormContext();

  const backgrounds = useMemo(() => {
    return data.backgrounds;
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Paisagem</Typography>
      </Box>

      <Controller
        name="view"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue={backgrounds[0].file}
            aria-label="View"
            onValueChange={(value) => {
              onChange(value ? (value as any) : _value)
              }
              }
          >
            <Carousel>
              {backgrounds?.map((background) => (
                <Fragment key={background.id}>
                  <ToggleGroupItemCarousel
                    value={background.file}
                    aria-label={`Fundo ${background.id}`}
                  >
                    <Image
                      alt=""
                      src={background.file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Fundo ${background.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />
    </Fragment>
  );
};

export const BodyAndEyesForm = () => {
  const { control } = useFormContext();
  const { generateSku } = useAuth();
  const { gender, breed } = useWatch({
    control,
  });

  const body = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.body;
  }, [breed, gender]);

  const eyes = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.eyes;
  }, [breed, gender]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Corpo</Typography>
      </Box>

      <Controller
        name="body"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'body');
                onChange(value ? (value as any) : _value)}
              }
          >
            <Carousel>
              {body?.map((body) => (
                <Fragment key={body.id}>
                  <ToggleGroupItemCarousel
                    value={body.file}
                    aria-label={`Corpo ${body.id}`}
                  >
                    <Image
                      alt=""
                      src={body.file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Corpo ${body.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Olhos</Typography>
      </Box>
      <Controller
        name="eyes"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'eye');
                onChange(value);
              }
              }
          >
            <Carousel>
              {eyes?.map((eye, index) => (
                <Fragment key={eye.id}>
                  <ToggleGroupItemCarousel
                    value={eye.file}
                    aria-label={`Olhos ${eye.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.eyes[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Olhos ${eye.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />
    </Fragment>
  );
};

export const ClothesAndGlassesForm = () => {
  const { control } = useFormContext();
  const { generateSku } = useAuth();
  const { gender, breed } = useWatch({
    control,
  });

  const clothes = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.clothes;
  }, [breed, gender]);

  const glasses = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.glasses;
  }, [breed, gender]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Roupas</Typography>
      </Box>

      <Controller
        name="clothes"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'clothe');
                onChange(value)
              }
              }
          >
            <Carousel>
              {clothes?.map((clothe, index) => (
                <Fragment key={clothe.id}>
                  <ToggleGroupItemCarousel
                    value={clothe.file}
                    aria-label={`Roupa ${clothe.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.clothes[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Roupa ${clothe.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Óculos</Typography>
      </Box>
      <Controller
        name="glasses"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'glass');
                onChange(value)
              }
              }
          >
            <Carousel>
              {glasses?.map((glass, index) => (
                <Fragment key={glass.id}>
                  <ToggleGroupItemCarousel
                    value={glass.file}
                    aria-label={`Óculos ${glass.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.glasses[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Óculos ${glass.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />

    </Fragment>
  );
};

export const CapAndNecklaceForm = () => {
  const { control } = useFormContext();
  const { generateSku } = useAuth();
  const { gender, breed } = useWatch({
    control,
  });

  const necklaces = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.necklaces;
  }, [breed, gender]);

  const caps = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.caps;
  }, [breed, gender]);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Colar</Typography>
      </Box>

      <Controller
        name="necklace"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'necklace');
                onChange(value)
              }
              }
          >
            <Carousel>
              {necklaces?.map((necklace, index) => (
                <Fragment key={necklace.id}>
                  <ToggleGroupItemCarousel
                    value={necklace.file}
                    aria-label={`Colar ${necklace.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.necklaces[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Colar ${necklace.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Boné</Typography>
      </Box>
      <Controller
        name="cap"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'cap');
                onChange(value)
              }
              }
          >
            <Carousel>
              {caps?.map((cap, index) => (
                <Fragment key={cap.id}>
                  <ToggleGroupItemCarousel
                    value={cap.file}
                    aria-label={`Boné ${cap.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.caps[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Boné ${cap.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />
    </Fragment>
  );
};

export const ArmsForm = () => {
  const { control } = useFormContext();
  const { generateSku } = useAuth();
  const { gender, breed } = useWatch({
    control,
  });

  const arms = useMemo(() => {
    return data[gender as "male" | "female"].breeds.find(
      (_breed) => _breed.name === breed
    )?.arms;
  }, [breed, gender]);


  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Braço</Typography>
      </Box>

      <Controller
        name="arm"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            defaultValue="male"
            aria-label="Gender"
            onValueChange={(value) => {
              generateSku(value.split('/')[value.split('/').length - 1], 'arm');
              onChange(value)
            }
            }
          >
            <Carousel>
              {arms?.map((arm, index) => (
                <Fragment key={arm.id}>
                  <ToggleGroupItemCarousel
                    value={arm.file}
                    aria-label={`Braço ${arm.id}`}
                  >
                    <Image
                      alt=""
                      src={data.defaultIcons.arms[index].file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Braço ${arm.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />
    </Fragment>
  );
};

export const FrameForm = () => {
  const { control } = useFormContext();

  const frames = useMemo(() => {
    return data.frames;
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 28,
          marginTop: 24,
        }}
      >
        <Typography>Moldura</Typography>
      </Box>

      <Controller
        name="frame"
        control={control}
        render={({ field: { onChange, value: _value } }) => (
          <ToggleGroupCarousel
            value={_value}
            type="single"
            aria-label="Gender"
            onValueChange={(value) => {
              onChange(value)
              }
              }
          >
            <Carousel>
              {frames?.map((frame) => (
                <Fragment key={frame.id}>
                  <ToggleGroupItemCarousel
                    value={frame.file}
                    aria-label={`Frame ${frame.id}`}
                  >
                    <Image
                      alt=""
                      src={frame.file}
                      width={80}
                      height={80}
                      layout="fixed"
                      aria-label={`Frame ${frame.id}`}
                    />
                  </ToggleGroupItemCarousel>
                </Fragment>
              ))}
            </Carousel>
          </ToggleGroupCarousel>
        )}
      />
    </Fragment>
  );
};
