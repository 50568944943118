import * as TabsPrimitive from "@radix-ui/react-tabs";
import styled from "styled-components";

const StyledTabs = styled(TabsPrimitive.Root)`
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const StyledList = styled(TabsPrimitive.List)`
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledTrigger = styled(TabsPrimitive.Trigger)`
  all: unset;
  border-bottom: 1px solid #bcb0b0;
  padding: 14px 32px;
  font-family: inherit;
  display: flex;
  font-size: 15;
  line-height: 1;
  user-select: none;
  &[data-state="active"] {
    border-bottom: 2px solid black;
  }
  @media (max-width: 64rem) {
    padding: 14px 16px;
  }
`;

const StyledContent = styled(TabsPrimitive.Content)`
  flex-grow: 1;
  background-color: "white";
  border-bottom-left-radius: 6;
  border-bottom-right-radius: 6;
  outline: none;
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
`;

// Exports
export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;
