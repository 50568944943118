import styled, { css } from 'styled-components'

export const StyledCarousel = styled.div`
  position: relative;
`

export const StyledCarouselViewport = styled.div`
  overflow: hidden;
  max-width: 736px;

  @media (max-width: 425px) {
    max-width: 300px;
  }
`

export const StyledCarouselContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;

  max-height: 300px;
`
export const StyledCarouselSlide = styled.div`
  position: relative;
  min-width: 120px;
`
export const StyledCarouselSlideInner = styled.div<{ height: number | string }>`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => `${height}px` };
  border-radius: 5px;
`

type StyledCarouselButtonProps = {
  actionType: 'next' | 'previous'
}

export const StyledCarouselButton = styled.button<StyledCarouselButtonProps>`
  background: #FFE8AB;  
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 40px;
  height: 40px;

  ${({ actionType }) =>
    actionType === 'next'
      ? css`
          right: -50px;
        `
      : css`
          left: -50px;
        `}
`
