import styled, { css } from "styled-components";

interface ButtonProps {
  isNotVisible?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  background: #f0c244;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 100px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }

  ${(props) => props.isNotVisible && css`
    display: none;
  `}

  :active {
    box-shadow: inset 4px 4px 0px #000000;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
