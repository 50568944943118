import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  
  width: 100%;
  padding: 42px;
  min-height: 488px;

  background: #faf9f5;
  border: 4px solid #000000;
  box-shadow: 8px 8px 0px #000000;
  border-radius: 16px;

  position: relative;

  @media (max-width: 1346px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 200px;
  }
`;
