import styled from "styled-components";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";

export const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  margin-bottom: 4px;
`;

export const Input = styled.input`
  background: #ffffff;
  width: 440px;
  border: 1px solid #1e120d;
  border-radius: 4px;
  padding: 10px;

  @media(max-width: 425px) {
    width: 300px
  }

  &:focus {
    box-shadow: 0 0 2px #1e120d;
  }
`;

export const ToggleGroup = styled(ToggleGroupPrimitive.Root)`
  display: flex;
  gap: 16px;
`;

export const ToggleGroupItem = styled(ToggleGroupPrimitive.Item)`
  all: unset;
  padding: 8px;
  background: #ffffff;
  border: 1px solid #1e120d;
  border-radius: 4px;
  &[data-state="on"] {
    background-color: #FFDB7A;
  }
  :focus {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }
`;

export const ToggleGroupCarousel = styled(ToggleGroupPrimitive.Root)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
`;

export const ToggleGroupItemCarousel = styled(ToggleGroupPrimitive.Item)`
  all: unset;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  background: #fff;
  border: 1px solid #1e120d;
  &[data-state="on"] {
    border: 3px solid #1e120d;
    background: #FAF3DC;
  }
  :focus {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }
`;

export const ContainerBreeds = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContainerBreed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-width: 100px;
`;