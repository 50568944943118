import { ReactNode, useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { Children } from "react";

import {
  StyledCarousel,
  StyledCarouselButton,
  StyledCarouselContainer,
  StyledCarouselSlide,
  StyledCarouselSlideInner,
  StyledCarouselViewport,
} from "./carousel.styles";

import { Icon } from "@doare/ui-components";
import { ArrowLeftIcon, ArrowRightIcon } from "../icons/icons.component";

type Carousel = {
  children: ReactNode;
  height?: string;
};

export const Carousel = ({ children, height = '444px' }: Carousel) => {
  const [viewportRef, embla] = useEmblaCarousel({
    inViewThreshold: 10,
    align: 'start',
    dragFree: true,
    loop: false,
    draggable: true,
    containScroll: 'trimSnaps'
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(false);
    setNextBtnEnabled(false);
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const arrayChildren = Children.toArray(children);

  return (
    <>
      <StyledCarousel>
        <StyledCarouselViewport ref={viewportRef}>
          <StyledCarouselContainer>
            {Children.map(arrayChildren, (child, index) => (
              <StyledCarouselSlide key={index}>
                <StyledCarouselSlideInner height={height}>
                  {child}
                </StyledCarouselSlideInner>
              </StyledCarouselSlide>
            ))}
          </StyledCarouselContainer>
        </StyledCarouselViewport>
        {prevBtnEnabled && (
          <StyledCarouselButton onClick={scrollPrev} actionType="previous">
            <Icon size={12}>
              <ArrowLeftIcon />
            </Icon>
          </StyledCarouselButton>
        )}
        {nextBtnEnabled && (
          <StyledCarouselButton onClick={scrollNext} actionType="next">
            <Icon size={12}>
              <ArrowRightIcon />
            </Icon>
          </StyledCarouselButton>
        )}
      </StyledCarousel>
    </>
  );
};
