export const data = {
  male: {
    breeds: [
      {
        id: 1,
        name: 'Galgo Afegão',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/01-M.png',
        body: [
          {
            id: 1,
            file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Corpo/01-MMA.png",
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Corpo/01-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Corpo/01-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Colar/01-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Colar/01-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Olhos/01-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Olhos/01-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Olhos/01-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Olhos/01-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/01-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/Camiseta/63-MMF01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/%C3%93CULOS/01-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/%C3%93CULOS/01-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/%C3%93CULOS/01-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bon%C3%A9/01-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bra%C3%A7os/01-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bra%C3%A7os/01-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bra%C3%A7os/01-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/bra%C3%A7os/01-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/Macho/63-MMF01.png',
          },
        ]
      },
      {
        id: 2,
        name: 'Akita',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/02-M.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+4/corpo/02-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+4/corpo/02-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+4/corpo/02-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/colar/02-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/colar/02-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/Olhos/02-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/Olhos/02-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/Olhos/02-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+5/Olhos/02-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MMM01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MOC04.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MOC05.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MOC06.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MOC07.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/camisetas/02-MOC08.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/%C3%B3culos/02-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/%C3%B3culos/02-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+6/%C3%B3culos/02-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/Bon%C3%A9/02-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/bra%C3%A7os/02-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/bra%C3%A7os/02-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/bra%C3%A7os/02-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+7/bra%C3%A7os/02-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/MACHO/N%C3%ADvel+8/microfone/02-MMF01.png',
          },
        ]
      },
      {
        id: 3,
        name: 'American Bully',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/03-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+4/corpo/03-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+4/corpo/03-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+4/corpo/03-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/colar/03-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/colar/03-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/olho/03-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/olho/03-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/olho/03-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+5/olho/03-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/camisetas/03-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/%C3%B3culos/03-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/%C3%B3culos/03-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+6/%C3%B3culos/03-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bon%C3%A9/03-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bra%C3%A7os/03-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bra%C3%A7os/03-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bra%C3%A7os/03-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+7/Bra%C3%A7os/03-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/Macho/N%C3%ADvel+8/microfone/03-MMM01.png',
          },
        ]
      },
      {
        id: 4,
        name: 'American Staffordshire Terrier',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/04-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+4/corpo/04-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+4/corpo/04-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+4/corpo/04-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/colar/04-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/colar/04-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/olho/04-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/olho/04-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/olho/04-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+5/olho/04-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/camisetas/04-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/%C3%B3culos/04-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/%C3%B3culos/04-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+6/%C3%B3culos/04-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bon%C3%A9/04-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bra%C3%A7os/04-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bra%C3%A7os/04-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bra%C3%A7os/04-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+7/Bra%C3%A7os/04-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/Macho/N%C3%ADvel+8/MICROFONE/04-MMF01.png',
          },
        ]
      },
      {
        id: 5,
        name: 'Basenji',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/05-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+4/corpo/05-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+4/corpo/05-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+4/corpo/05-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/colar/05-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/colar/05-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/olho/05-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/olho/05-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/olho/05-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+5/olho/05-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/camisetas/05-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/%C3%B3culos/05-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/%C3%B3culos/05-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+6/%C3%B3culos/05-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bon%C3%A9/05-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bra%C3%A7os/05-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bra%C3%A7os/05-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bra%C3%A7os/05-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+7/Bra%C3%A7os/05-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/Macho/N%C3%ADvel+8/MICROFONE/05-MMF01.png',
          },
        ]
      },
      {
        id: 6,
        name: 'Basset Hound',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/06-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+4/corpo/06-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+4/corpo/06-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+4/corpo/06-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/colar/06-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/colar/06-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/olho/06-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/olho/06-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/olho/06-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+5/olho/06-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/camisetas/06-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/%C3%B3culos/06-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/%C3%B3culos/06-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+6/%C3%B3culos/06-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bon%C3%A9/06-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bra%C3%A7os/06-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bra%C3%A7os/06-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bra%C3%A7os/06-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+7/Bra%C3%A7os/06-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/Macho/N%C3%ADvel+8/MICROFONE/06-MMF01.png',
          },
        ]
      },
      {
        id: 7,
        name: 'Beagle',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/07-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+4/corpo/07-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+4/corpo/07-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+4/corpo/07-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/colar/07-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/colar/07-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/olho/07-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/olho/07-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/olho/07-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+5/olho/07-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/camisetas/07-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/%C3%B3culos/07-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/%C3%B3culos/07-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+6/%C3%B3culos/07-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bon%C3%A9/07-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bra%C3%A7os/07-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bra%C3%A7os/07-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bra%C3%A7os/07-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+7/Bra%C3%A7os/07-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/Macho/N%C3%ADvel+8/MICROFONE/07-MMF01.png',
          },
        ]
      },
      {
        id: 8,
        name: "Bernese",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/08-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+4/corpo/08-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+4/corpo/08-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+4/corpo/08-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/colar/08-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/colar/08-MOL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/olho/08-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/olho/08-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/olho/08-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+5/olho/08-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/camisetas/08-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/%C3%B3culos/08-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/%C3%B3culos/08-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+6/%C3%B3culos/08-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bon%C3%A9/08-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bra%C3%A7os/08-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bra%C3%A7os/08-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bra%C3%A7os/08-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+7/Bra%C3%A7os/08-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/Macho/N%C3%ADvel+8/MICROFONE/08-MMM01.png',
          },
        ]
      },
      {
        id: 9,
        name: "Bichon Frisé",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/09-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+4/corpo/09-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+4/corpo/09-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+4/corpo/09-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/colar/09-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/colar/09-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/olho/09-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/olho/09-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/olho/09-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+5/olho/09-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/camisetas/09-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/%C3%B3culos/09-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/%C3%B3culos/09-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+6/%C3%B3culos/09-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bon%C3%A9/09-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bra%C3%A7os/09-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bra%C3%A7os/09-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bra%C3%A7os/09-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+7/Bra%C3%A7os/09-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/Macho/N%C3%ADvel+8/MICROFONE/09-MMF01.png',
          },
        ]
      },
      {
        id: 10,
        name: "Bloodhound",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/10-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+4/corpo/10-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+4/corpo/10-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+4/corpo/10-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/colar/10-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/colar/10-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/olho/10-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/olho/10-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/olho/10-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+5/olho/10-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/camisetas/10-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/%C3%B3culos/10-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/%C3%B3culos/10-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+6/%C3%B3culos/10-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bon%C3%A9/10-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bra%C3%A7os/10-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bra%C3%A7os/10-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bra%C3%A7os/10-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+7/Bra%C3%A7os/10-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/Macho/N%C3%ADvel+8/MICROFONE/10-MMF01.png',
          },
        ]
      },
      {
        id: 11,
        name: "Boiadeiro Autraliano",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/11-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+4/corpo/11-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+4/corpo/11-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+4/corpo/11-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/colar/11-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/colar/11-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/olho/11-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/olho/11-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/olho/11-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+5/olho/11-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/camisetas/11-MCM08.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/%C3%B3culos/11-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/%C3%B3culos/11-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+6/%C3%B3culos/11-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bon%C3%A9/11-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bra%C3%A7os/11-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bra%C3%A7os/11-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bra%C3%A7os/11-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+7/Bra%C3%A7os/11-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/Macho/N%C3%ADvel+8/MICROFONE/11-MCF0X.png',
          },
        ]
      },
      {
        id: 12,
        name: "Border Collie",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/12-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+4/corpo/12-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+4/corpo/12-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+4/corpo/12-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/colar/12-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/colar/12-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/olho/12-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/olho/12-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/olho/12-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+5/olho/12-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/camisetas/12-MMM01.png',
          }
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/%C3%B3culos/12-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/%C3%B3culos/12-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+6/%C3%B3culos/12-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bon%C3%A9/12-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bra%C3%A7os/12-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bra%C3%A7os/12-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bra%C3%A7os/12-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+7/Bra%C3%A7os/12-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/Macho/N%C3%ADvel+8/MICROFONE/12-MMF01.png',
          },
        ]
      },
      {
        id: 13,
        name: "Borzoi",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/13-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+4/corpo/13-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+4/corpo/13-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+4/corpo/13-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/colar/13-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/colar/13-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/olho/13-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/olho/13-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/olho/13-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+5/olho/13-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/camisetas/13-MMM01.png',
          }
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/%C3%B3culos/13-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/%C3%B3culos/13-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+6/%C3%B3culos/13-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bon%C3%A9/13-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bra%C3%A7os/13-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bra%C3%A7os/13-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bra%C3%A7os/13-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+7/Bra%C3%A7os/13-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/Macho/N%C3%ADvel+8/MICROFONE/13-MMF01.png',
          },
        ]
      },
      {
        id: 14,
        name: "Boston Terrier",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/14-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+4/corpo/14-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+4/corpo/14-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+4/corpo/14-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/colar/14-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/colar/14-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/olhos/14-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/olhos/14-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/olhos/14-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+5/olhos/14-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/camisetas/14-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/%C3%B3culos/14-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/%C3%B3culos/14-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+6/%C3%B3culos/14-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/Bon%C3%A9/14-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/bra%C3%A7os/14-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/bra%C3%A7os/14-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/bra%C3%A7os/14-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+7/bra%C3%A7os/14-MSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/Macho/N%C3%ADvel+8/Micro/14-MMF01.png',
          },
        ]
      },
      {
        id: 15,
        name: "Boxer",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/15-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+4/corpo/15-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+4/corpo/15-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+4/corpo/15-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/colar/15-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/colar/15-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/olho/15-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/olho/15-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/olho/15-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+5/olho/15-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/camisetas/15-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/%C3%B3culos/15-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/%C3%B3culos/15-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+6/%C3%B3culos/15-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bon%C3%A9/15-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bra%C3%A7os/15-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bra%C3%A7os/15-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bra%C3%A7os/15-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+7/Bra%C3%A7os/15-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/Macho/N%C3%ADvel+8/MICROFONE/15-MMF01.png',
          },
        ]
      },
      {
        id: 16,
        name: "Bull Terrier",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/16-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+4/corpo/16-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+4/corpo/16-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+4/corpo/16-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/colar/16-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/colar/16-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/olho/16-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/olho/16-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/olho/16-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+5/olho/16-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/camisetas/16-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/%C3%B3culos/16-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/%C3%B3culos/16-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+6/%C3%B3culos/16-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bon%C3%A9/16-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bra%C3%A7os/16-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bra%C3%A7os/16-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bra%C3%A7os/16-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+7/Bra%C3%A7os/16-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/Macho/N%C3%ADvel+8/MICROFONE/16-MMM01.png',
          },
        ]
      },
      {
        id: 17,
        name: "Bulldogue Francês",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/17-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+4/Corpo/17-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+4/Corpo/17-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+4/Corpo/17-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/colar/17-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/colar/17-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/olhos/17-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/olhos/17-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/olhos/17-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+5/olhos/17-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/camiseta/17-MMF01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/%C3%B3culos/17-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/%C3%B3culos/17-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+6/%C3%B3culos/17-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bon%C3%A9/17-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bra%C3%A7os/17-FEX02.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bra%C3%A7os/17-MBN0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bra%C3%A7os/17-MCF0X.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+7/bra%C3%A7os/17-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/Macho/N%C3%ADvel+8/Microfone/17-MBN04.png',
          },
        ]
      },
      {
        id: 25,
        name: "Dachshund",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/25-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+4/Corpo/25-MPR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+4/Corpo/25-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+4/Corpo/25-MMA.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/colar/25-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/colar/25-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/olhos/25-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/olhos/25-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/olhos/25-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+5/olhos/25-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/camiseta/25-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/%C3%B3culos/25-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/%C3%B3culos/25-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+6/%C3%B3culos/25-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bon%C3%A9/25-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bra%C3%A7os/25-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bra%C3%A7os/25-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bra%C3%A7os/25-MSV01.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+7/bra%C3%A7os/74-MEX02.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/Macho/N%C3%ADvel+8/Microfone/25-MMF01.png',
          },
        ]
      },
      {
        id: 27,
        name: "Dobermann",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/27-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+4/corpo/27-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+4/corpo/27-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+4/corpo/27-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/colar/27-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/colar/27-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/olhos/27-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/olhos/27-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/olhos/27-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+5/olhos/27-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/camisetas/27-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/%C3%B3culos/27-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/%C3%B3culos/27-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+6/%C3%B3culos/27-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/Bon%C3%A9/27-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/bra%C3%A7os/27-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/bra%C3%A7os/27-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/bra%C3%A7os/27-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+7/bra%C3%A7os/27-MSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/Macho/N%C3%ADvel+8/Micro/27-MCL01.png',
          },
        ]
      },
      {
        id: 36,
        name: "Husky",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/36-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+4/corpo/36-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+4/corpo/36-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+4/corpo/36-MPR.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/olhos/36-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/olhos/36-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/olhos/36-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/olhos/36-MOL04.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/colar/36-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+5/colar/36-MCL02.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/camisetas/36-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/%C3%B3culos/36-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/%C3%B3culos/36-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+6/%C3%B3culos/36-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/Bon%C3%A9/36-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/bra%C3%A7os/36-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/bra%C3%A7os/36-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/bra%C3%A7os/36-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+7/bra%C3%A7os/36-MSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/Macho/N%C3%ADvel+8/Micro/36-MMF01.png',
          },
        ],
      },
      {
        id: 58,
        name: "Poodle",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/58-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+4/corpo/58-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+4/corpo/58-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+4/corpo/58-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/colar/58-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/colar/58-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/olhos/58-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/olhos/58-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/olhos/58-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+5/olhos/58-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/camisetas/58-MMF01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/%C3%B3culos/58-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/%C3%B3culos/58-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+6/%C3%B3culos/58-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/Bon%C3%A9/58-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/bra%C3%A7os/58-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/bra%C3%A7os/58-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/bra%C3%A7os/58-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+7/bra%C3%A7os/58-MSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/Macho/N%C3%ADvel+8/Micro/58-MMF01.png',
          },
        ]
      },
      {
        id: 60,
        name: "Pug",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/PUG-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+04/corpo/60-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+04/corpo/60-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+04/corpo/60-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/colar/60-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/colar/60-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/olhos/60-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/olhos/60-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/olhos/60-MOL03.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+05/olhos/60-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/camisetas/60-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/%C3%B3culos/60-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/%C3%B3culos/60-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+06/%C3%B3culos/60-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/Bon%C3%A9/60-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/bra%C3%A7os/60-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/bra%C3%A7os/60-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/bra%C3%A7os/60-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+07/bra%C3%A7os/60-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/Macho/N%C3%ADvel+08/Micro/60-MMF01.png',
          },
        ]
      },
      {
        id: 61,
        name: "Rottweiler",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/61-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+04/corpo/61-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+04/corpo/61-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+04/corpo/61-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/colar/61-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/colar/61-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/olhos/61-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/olhos/61-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/olhos/61-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+05/olhos/61-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/camiseta/61-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/%C3%B3culos/61-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/%C3%B3culos/61-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+06/%C3%B3culos/61-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bon%C3%A9/61-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bra%C3%A7o/61-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bra%C3%A7o/61-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bra%C3%A7o/61-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+07/Bra%C3%A7o/61-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/macho/N%C3%ADvel+08/Micro/61-MMF01.png',
          },
        ]
      },
      {
        id: 63,
        name: 'Samoieda',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/63-SAM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+4/corpo/63-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+4/corpo/63-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+4/corpo/63-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/colar/63-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/colar/63-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/olhos/63-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/olhos/63-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/olhos/63-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+5/olhos/63-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/camisetas/63-MMF01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/%C3%B3culos/63-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/%C3%B3culos/63-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+6/%C3%B3culos/63-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/Bon%C3%A9/63-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/bra%C3%A7os/63-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/bra%C3%A7os/63-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/bra%C3%A7os/63-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+7/bra%C3%A7os/63-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/Macho/N%C3%ADvel+8/Micro/63-MMF01.png',
          },
        ]
      },
      {
        id: 69,
        name: "Shitzu",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/69-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+4/corpo/69-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+4/corpo/69-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+4/corpo/69-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/colar/69-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/colar/69-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/olhos/69-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/olhos/69-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/olhos/69-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+5/olhos/69-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/camisetas/69-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/%C3%B3culos/69-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/%C3%B3culos/69-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+6/%C3%B3culos/69-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/Bon%C3%A9/69-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/bra%C3%A7os/69-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/bra%C3%A7os/69-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/bra%C3%A7os/69-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+7/bra%C3%A7os/69-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/Macho/N%C3%ADvel+8/Micro/69-MMF01.png',
          },
        ]
      },
      {
        id: 70,
        name: 'Loulou',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/70-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+4/corpo/70-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+4/corpo/70-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+4/corpo/70-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/colar/70-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/colar/70-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/olhos/70-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/olhos/70-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/olhos/70-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+5/olhos/70-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/camisetas/70-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/%C3%B3culos/70-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/%C3%B3culos/70-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+6/%C3%B3culos/70-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/Bon%C3%A9/70-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/bra%C3%A7os/70-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/bra%C3%A7os/70-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/bra%C3%A7os/70-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+7/bra%C3%A7os/70-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/Macho/N%C3%ADvel+8/Micro/70-MMF01.png',
          },
        ]
      },
      {
        id: 74,
        name: "Vira-lata (srd)",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/74-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+4/corpo/74-MBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+4/corpo/74-MMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+4/corpo/74-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/colar/74-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/colar/74-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/olhos/74-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/olhos/74-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/olhos/74-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+5/olhos/74-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/camiseta/74-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/74-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/74-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/74-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bon%C3%A9/74-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bra%C3%A7os/74-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bra%C3%A7os/74-MCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bra%C3%A7os/74-MEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+7/bra%C3%A7os/74-MSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/Macho/N%C3%ADvel+8/microfone/74-MMF01.png',
          },
        ]
      },
      {
        id: 79,
        name: "Vira-lata",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/79-MAC.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+4/corpo/79-MMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+4/corpo/79-MOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+4/corpo/79-MPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/colar/79-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/colar/79-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/olho/79-MOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/olho/79-MOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/olho/79-MOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+5/olho/79-MOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/camisetas/79-MMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/79-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/79-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+6/%C3%B3culos/79-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bon%C3%A9/79-MBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bon%C3%A9/79-MBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bon%C3%A9/79-MBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bon%C3%A9/79-MBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bon%C3%A9/79-MTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bra%C3%A7os/79-MBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bra%C3%A7os/79-MCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bra%C3%A7os/79-MEX01.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+7/Bra%C3%A7os/79-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/Macho/N%C3%ADvel+8/MICROFONE/79-MMF01.png',
          },
        ]
      },
    ],
  },
  female: {
    breeds: [
      {
        id: 1,
        name: 'Galgo Afegão',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/01-F.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+4/Corpo/01-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+4/Corpo/01-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+4/Corpo/01-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/colar/01-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/colar/01-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/olhos/01-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/olhos/01-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/olhos/01-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+5/olhos/01-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/camisetas/01-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/%C3%B3culos/01-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/%C3%B3culos/01-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+6/%C3%B3culos/01-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bon%C3%A9/01-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bra%C3%A7os/01-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bra%C3%A7os/01-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bra%C3%A7os/01-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+7/bra%C3%A7os/01-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/01-AFG/F%C3%AAmea+/N%C3%ADvel+8/01-FMF01.png',
          },
        ]
      },
      {
        id: 2,
        name: 'Akita',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/02-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+4/corpo/02-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+4/corpo/02-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+4/corpo/02-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/colar/02-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/colar/02-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/olhos/02-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/olhos/02-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/olhos/02-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+5/olhos/02-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/camisetas/02-FCM08.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/02-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/02-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/02-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/02-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/02-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/02-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/02-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/02-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/02-AKI/F%C3%AAmea/N%C3%ADvel+8/microfone/02-FMF01.png',
          },
        ]
      },
      {
        id: 3,
        name: 'American Bully',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/03-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+4/corpo/03-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+4/corpo/03-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+4/corpo/03-FOU.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/colar/03-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/colar/03-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/olho/03-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/olho/03-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/olho/03-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+5/olho/03-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/camisetas/03-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/03-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/03-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/03-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/03-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/03-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/03-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/03-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/03-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/03-AMB/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/03-FMF01.png',
          },
        ]
      },
      {
        id: 4,
        name: 'American Staffordshire Terrier',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/04-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+4/corpo/04-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+4/corpo/04-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+4/corpo/04-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/colar/04-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/colar/04-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/olho/04-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/olho/04-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/olho/04-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+5/olho/04-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/camisetas/04-FCM08.png',
          }
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/04-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/04-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/04-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/04-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/04-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/04-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/04-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/04-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/04-AMS/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/04-FMF01.png',
          },
        ]
      },
      {
        id: 5,
        name: 'Basenji',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/05-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+4/corpo/05-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+4/corpo/05-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+4/corpo/05-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/colar/05-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/colar/05-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/olho/05-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/olho/05-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/olho/05-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+5/olho/05-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/camisetas/05-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/05-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/05-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/05-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/05-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/05-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/05-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/05-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/05-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/05-BAS/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/05-FMF01.png',
          },
        ]
      },
      {
        id: 6,
        name: 'Basset Hound',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/06-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+4/corpo/06-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+4/corpo/06-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+4/corpo/06-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/colar/06-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/colar/06-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/olho/06-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/olho/06-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/olho/06-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+5/olho/06-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/camisetas/06-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/06-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/06-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/06-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/06-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/06-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/06-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/06-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/06-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/06-BTS/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/06-FMM01.png',
          },
        ]
      },
      {
        id: 7,
        name: 'Beagle',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/07-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+4/corpo/07-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+4/corpo/07-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+4/corpo/07-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/colar/07-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/colar/07-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/olho/07-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/olho/07-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/olho/07-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+5/olho/07-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/camisetas/07-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/07-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/07-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/07-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FEX02.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/07-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/07-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/07-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/07-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/07-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/07-BEA/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/07-FMF01.png',
          },
        ]
      },
      {
        id: 8,
        name: "Bernese",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/08-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+4/corpo/08-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+4/corpo/08-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+4/corpo/08-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/colar/08-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/colar/08-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/olho/08-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/olho/08-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/olho/08-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+5/olho/08-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/camisetas/08-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/08-MOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/08-MOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/08-MOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/08-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/08-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/08-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/08-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/08-MSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/08-BER/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/08-FMF01.png',
          },
        ]
      },
      {
        id: 9,
        name: "Bichon Frisé",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/09-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+4/corpo/09-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+4/corpo/09-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+4/corpo/09-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/colar/09-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/colar/09-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/olho/09-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/olho/09-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/olho/09-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+5/olho/09-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/camisetas/09-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/09-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/09-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/09-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/09-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/09-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/09-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/09-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/09-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/09-BIC/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/09-FMF01.png',
          },
        ]
      },
      {
        id: 10,
        name: "Bloodhound",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/10-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+4/corpo/10-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+4/corpo/10-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+4/corpo/10-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/colar/10-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/colar/10-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/olho/10-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/olho/10-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/olho/10-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+5/olho/10-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/camisetas/10-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/10-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/10-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/10-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/10-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/10-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/10-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/10-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/10-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/10-BLO/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/10-FMF01.png',
          },
        ]
      },
      {
        id: 11,
        name: "Boiadeiro Autraliano",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/11-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+4/corpo/11-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+4/corpo/11-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+4/corpo/11-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/colar/11-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/colar/11-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/olho/11-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/olho/11-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/olho/11-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+5/olho/11-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/camisetas/11-FMM01.png'
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/11-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/11-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/11-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/11-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/11-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/11-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/11-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/11-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/11-BOI/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/11-FMF01.png',
          },
        ]
      },
      {
        id: 12,
        name: "Border Collie",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/12-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+4/corpo/12-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+4/corpo/12-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+4/corpo/12-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/colar/12-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/colar/12-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/olho/12-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/olho/12-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/olho/12-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+5/olho/12-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FBN08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/camisetas/12-FMM01.png'
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/12-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/12-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/12-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/12-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/12-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/12-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/12-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/12-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/12-BOR/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/12-FMF01.png',
          },
        ]
      },
      {
        id: 13,
        name: "Borzoi",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/13-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+4/corpo/13-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+4/corpo/13-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+4/corpo/13-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/colar/13-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/colar/13-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/olho/13-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/olho/13-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/olho/13-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+5/olho/13-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/camisetas/13-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/13-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/13-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/13-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/13-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/13-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/13-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/13-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/13-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/13-BZI/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/13-FMM01.png',
          },
        ]
      },
      {
        id: 14,
        name: "Boston Terrier",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/14-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+4/Corpo/14-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+4/Corpo/14-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+4/Corpo/14-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/Colar/14-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/Colar/14-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/olhos/14-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/olhos/14-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/olhos/14-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+5/olhos/14-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14+SEM.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM03.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM04.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM05.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM06.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM07.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FCM08.png',
          },
          {
            id: 10,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/camisetas/14-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/14-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/14-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/14-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/14-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/14-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/14-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/14-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/14-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/14-BTN/F%C3%AAmea/N%C3%ADvel+8/Micro/14-FMF01.png',
          },
        ]
      },
      {
        id: 15,
        name: "Boxer",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/15-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+4/corpo/15-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+4/corpo/15-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+4/corpo/15-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/colar/15-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/colar/15-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/olho/15-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/olho/15-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/olho/15-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+5/olho/15-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/camisetas/15-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/15-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/15-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/15-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/15-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/15-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/15-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/15-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/15-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/15-BOX/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/15-FMF01.png',
          },
        ]
      },
      {
        id: 16,
        name: "Bull Terrier",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/16-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+4/corpo/16-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+4/corpo/16-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+4/corpo/16-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/colar/16-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/colar/16-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/olho/16-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/olho/16-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/olho/16-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+5/olho/16-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/camisetas/16-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/16-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/16-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/16-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/16-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/16-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/16-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/16-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/16-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/16-BTR/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/16-FMF01.png',
          },
        ]
      },
      {
        id: 17,
        name: "Bulldogue Francês",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/17-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+4/corpo/17-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+4/corpo/17-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+4/corpo/17-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/colar/17-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/colar/17-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/olhos/17-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/olhos/17-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/olhos/17-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+5/olhos/17-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/camiseta/17-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/17-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/17-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/17-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/17-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/17-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/17-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/17-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/17-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/17-BDF/F%C3%AAmea/N%C3%ADvel+8/micro/17-FMF01.png',
          },
        ]
      },
      {
        id: 25,
        name: "Dachshund",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/25-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+4/Corpo/25-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+4/Corpo/25-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+4/Corpo/25-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/colar/25-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/colar/25-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/olhos/25-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/olhos/25-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/olhos/25-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+5/olhos/25-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/camiseta/25-FTR01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/25-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/25-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/25-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bon%C3%A9/25-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/25-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/25-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/25-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/25-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/25-DAC/F%C3%AAmea/N%C3%ADvel+8/Microfone/25-FMF01.png',
          },
        ]
      },
      {
        id: 27,
        name: "Dobermann",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/27-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+4/corpo/27-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+4/corpo/27-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+4/corpo/27-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/colar/27-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/colar/27-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/olhos/27-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/olhos/27-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/olhos/27-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+5/olhos/27-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/camisetas/27-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/27-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/27-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/27-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/27-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/27-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/27-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/27-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/27-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/27-DOB/F%C3%AAmea/N%C3%ADvel+8/Micro/27-FMF01.png',
          },
        ]
      },
      {
        id: 36,
        name: "Husky",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/36-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+4/corpo/36-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+4/corpo/36-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+4/corpo/36-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/colar/36-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/colar/36-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/olhos/36-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/olhos/36-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/olhos/36-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+5/olhos/36-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/camisetas/36-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/36-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/36-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/36-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/36-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/36-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/36-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/36-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/36-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/36-HUS/F%C3%AAmea/N%C3%ADvel+8/Micro/36-FMF01.png',
          },
        ]
      },
      {
        id: 58,
        name: "Poodle",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/58-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+4/corpo/58-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+4/corpo/58-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+4/corpo/58-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/colar/58-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/colar/58-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/olhos/58-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/olhos/58-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/olhos/58-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+5/olhos/58-FBN04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/camisetas/58-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/58-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/58-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/58-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/58-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/58-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/58-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/58-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/58-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/58-POO/F%C3%AAmea/N%C3%ADvel+8/Micro/58-FMF01.png',
          },
        ]
      },
      {
        id: 60,
        name: "Pug",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/60-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+04/corpo/60-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+04/corpo/60-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+04/corpo/60-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/colar/60-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/colar/60-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/olhos/60-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/olhos/60-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/olhos/60-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+05/olhos/60-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/camisetas/60-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/60-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/60-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/60-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/60-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/60-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/60-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/60-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/60-FEX01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/bra%C3%A7os/60-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/bra%C3%A7os/60-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/bra%C3%A7os/60-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/N%C3%ADvel+07/bra%C3%A7os/60-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/60-PUG/F%C3%AAmea/Nivel+08/Micro/60-FMF01.png',
          },
        ]
      },      
      {
        id: 61,
        name: "Rottweiler",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/61-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+04/corpo/61-FPR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+04/corpo/61-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+04/corpo/61-FMA.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/colar/61-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/colar/61-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/olhos/61-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/olhos/61-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/olhos/61-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+05/olhos/61-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/camiseta/61-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/61-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/61-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/61-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/61-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/61-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/61-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/61-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/61-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/61-ROT/F%C3%AAmea/N%C3%ADvel+08/Micro/61-FMF01.png',
          },
        ]
      },
      {
        id: 63,
        name: 'Samoieda',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/63-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+4/corpo/63-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+4/corpo/63-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+4/corpo/63-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/colar/63-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/colar/63-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/olhos/63-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/olhos/63-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/olhos/63-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+5/olhos/63-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/camisetas/63-FCMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/63-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/63-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/63-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/63-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/63-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/63-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/63-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/63-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/63-SAM/F%C3%AAmea/N%C3%ADvel+8/Micro/63-FMF01.png',
          },
        ]
      },
      {
        id: 69,
        name: "Shitzu",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/69-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+4/corpo/69-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+4/corpo/69-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+4/corpo/69-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/colar/69-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/colar/69-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/olhos/69-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/olhos/69-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/olhos/69-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+5/olhos/69-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/camisetas/69-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/69-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/69-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/69-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/69-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/69-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/69-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/69-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/69-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/69-STZ/F%C3%AAmea/N%C3%ADvel+8/Micro/69-FEM.png',
          },
        ]
      },
      {
        id: 70,
        name: 'Loulou',
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/70-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+4/corpo/70-FBR.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+4/corpo/70-FMA.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+4/corpo/70-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/colar/70-MCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/colar/70-MCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/olhos/70-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/olhos/70-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/olhos/70-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+5/olhos/70-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/camisetas/70-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/70-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/70-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/70-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/70-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/70-FBN0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/70-FCF0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/70-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+7/bra%C3%A7os/70-FEX02.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/70-SPI/F%C3%AAmea/N%C3%ADvel+8/Micro/70-FMF01.png',
          },
        ]
      },
      {
        id: 74,
        name: "Vira-lata (srd)",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/74-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+04/Corpo/74-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+04/Corpo/74-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+04/Corpo/74-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/Colar/74-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/Colar/74-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/olhos/74-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/olhos/74-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/olhos/74-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+05/olhos/74-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM05.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM06.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FCM08.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/Camiseta/74-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/74-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/74-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+06/%C3%B3culos/74-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bon%C3%A9/74-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/74-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/74-FCF01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/74-FEX01.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+07/Bra%C3%A7o/74-FSV01.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/74-VRL/F%C3%AAmea/N%C3%ADvel+08/Micro/74-FMF01.png',
          },
        ]
      },
      {
        id: 79,
        name: "Vira-lata",
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/79-FEM.png',
        body: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+4/corpo/79-FMA.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+4/corpo/79-FOU.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+4/corpo/79-FPR.png',
          },
        ],
        necklaces: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/colar/79-FCL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/colar/79-FCL02.png',
          },
        ],
        eyes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/olho/79-FOL01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/olho/79-FOL02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/olho/79-FOL03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+5/olho/79-FOL04.png',
          },
        ],
        clothes: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM08.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM01.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM03.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM04.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM05.png',
          },
          {
            id: 7,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM07.png',
          },
          {
            id: 8,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-MCM06.png',
          },
          {
            id: 9,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/camisetas/79-FMM01.png',
          },
        ],
        glasses: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/79-FOC01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/79-FOC02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+6/%C3%B3culos/79-FOC03.png',
          },
        ],
        caps: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FBN01.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FBN02.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FBN03.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FBN04.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FEX01.png',
          },
          {
            id: 6,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bon%C3%A9/79-FTR01.png',
          },
        ],
        arms: [
          {
            id: 1,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/79--FCF0X.png',
          },
          {
            id: 2,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/79-FBN0X.png',
          },
          {
            id: 3,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/79-FEX02.png',
          },
          {
            id: 4,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+7/Bra%C3%A7os/79-FSV0X.png',
          },
          {
            id: 5,
            file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/breeds/79-VRL/F%C3%AAmea/N%C3%ADvel+8/MICROFONE/79-FMF01.png',
          },
        ]
      }
    ],
  },
  backgrounds: [
    {
      id: 1,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG01.png",
    },
    {
      id: 2,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG02.png",
    },
    {
      id: 3,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG03.png",
    },
    {
      id: 4,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG04.png",
    },
    {
      id: 5,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG05.png",
    },
    {
      id: 6,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG06.png",
    },
    {
      id: 7,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG07.png",
    },
    {
      id: 8,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG08.png",
    },
    {
      id: 9,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG09.png",
    },
    {
      id: 10,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG10.png",
    },
    {
      id: 11,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG11.png",
    },
    {
      id: 12,
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/pavilh%C3%A3o+C.png",
    }
  ],
  frames: [
    {
      id: 1,
      name: 'Bronze',
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/bronze+v5+-+final.png",
    },
    {
      id: 2,
      name: 'Prata',
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/prata+v5+-+final.png",
    },
    {
      id: 3,
      name: 'Ouro',
      file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/ouro+v5+-+final.png",
    },
  ],
  defaultIcons: {
    arms: [
      {
        id: 1,
        file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/arms/new/m%C3%A3o+01.png",
      },
      {
        id: 2,
        file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/arms/new/m%C3%A3o+02.png",
      },
      {
        id: 3,
        file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/arms/new/m%C3%A3o+03.png",
      },
      {
        id: 4,
        file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/arms/new/m%C3%A3o+04.png",
      },
      {
        id: 5,
        file: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/arms/new/m%C3%A3o+05.png",
      },
    ],
    caps: [
      {
        id: 1,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+01.png',
      },
      {
        id: 2,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+2.png',
      },
      {
        id: 3,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+3.png',
      },
      {
        id: 4,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+4.png',
      },
      {
        id: 5,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+5.png',
      },
      {
        id: 6,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/caps/new/bon%C3%A9+6.png',
      },
    ],
    clothes: [
      {
        id: 1,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+01.png',
      },
      {
        id: 2,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+02.png',
      },
      {
        id: 3,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+03.png',
      },
      {
        id: 4,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+04.png',
      },
      {
        id: 5,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+05.png',
      },
      {
        id: 6,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+06.png',
      },
      {
        id: 7,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+07.png',
      },
      {
        id: 8,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+08.png',
      },
      {
        id: 9,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/clothes/new/camisa+09.png',
      },
    ],
    eyes: [
      {
        id: 1,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/eyes/new/olhos+01.png',
      },
      {
        id: 2,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/eyes/new/olhos+02.png',
      },
      {
        id: 3,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/eyes/new/olhos+03.png',
      },
      {
        id: 4,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/eyes/new/olhos+04.png',
      },
    ],
    glasses: [
      {
        id: 1,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/glasses/new/oc+01.png',
      },
      {
        id: 2,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/glasses/new/oc+02.png',
      },
      {
        id: 3,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/glasses/new/oc+03.png',
      },
    ],
    necklaces: [
      {
        id: 1,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/necklaces/new/coleira+01.png',
      },
      {
        id: 2,
        file: 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/icons/necklaces/new/coleira+02.png'
      },
    ]
  }
};
